<template>
    <div>
      <van-sticky :offset-top="0" z-index="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('订单列表')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon name="home" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
      
      
      <van-tabs v-model:active="active" sticky>
          <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefresh"
          >
            <van-tab v-for="shop in ordertype" :title="shop.title" :key="shop.id" style="margin-top:20px;margin-bottom:10px;">
            <demo-block style="background-color: #ffffff;">
              <van-card v-for="i in lscurorder" :key="i.id"
                :price="i.opfare"
                :title="i.name + '      [' + i.outid + ']            ' + i.opdt  " 
                :desc="i.dpname"
                :thumb="i.facepic"
                style="margin-top:20px;margin-left:20px;margin-right:20px;"
                inset
                >
                <template #tags>
                  
                  <van-icon v-if="i.account_type ==='WXPAY' || i.account_type ==='WECHAT' || i.account_type === 'qywx'" name="wechat-pay" size="20" color="#09bb07" style="margin-top:2px;margin-right:9px;" />
                  <van-icon v-if="i.account_type ==='ALIPAY'" name="alipay" size="20" color="#1989fa" style="margin-right:9px;margin-top:5px;" />

                  <van-tag v-if="i.ispay === '0'" plain type="warning">未支付</van-tag>
                  <van-tag v-else-if="i.ispay === '99' || i.ispay === '100'" plain type="success">支付成功</van-tag>
                  <van-tag v-else-if="i.ispay === '1'" plain type="warning">支付中</van-tag>
                  <van-tag v-else plain type="danger">支付失败</van-tag>
                </template>
                <template #footer>
                    <van-button v-if="i.ispay === '0'"  icon="delete" size="mini" type="danger" @click="RemoveOrder(i)">删除订单</van-button>
                    <van-button v-if="i.ispay === '0' || i.ispay === '1'" icon="share" size="mini" @click="toRePay(i)" type="warning">发起支付</van-button>
                </template>
              </van-card>
            </demo-block>
          </van-tab>
        </van-pull-refresh>
        <van-button v-if="finished===false" :loading="isLoading" plain hairline type="primary" block @click="onLoad">更多...</van-button>
        <van-divider v-else>没有更多数据</van-divider>

      </van-tabs>
      
      
      <van-overlay :show="isLoading" >
        <view class="wrapper">
          <view class="block" catch:tap="noop">
            <van-loading size="24px" color="#ffffff">{{ loadcontent }}</van-loading>
          </view>
        </view>
      </van-overlay>
    </div>
  </template>
  
  <script>
  import { Checkbox, Button, Dialog, CheckboxGroup, Card, SubmitBar, Toast, Tabs, Tab, Sticky, NavBar, Icon, Tag,  Overlay, Loading, PullRefresh,Divider } from 'vant';
  import { requestGetPayRecentListInfo, requestPayDeleteOrder } from '../../api/api'
  
  export default {
    components: {
      [Button.name]: Button,
      [Card.name]: Card,
      [Checkbox.name]: Checkbox,
      [SubmitBar.name]: SubmitBar,
      [CheckboxGroup.name]: CheckboxGroup,
      [Sticky.name]: Sticky,
      [NavBar.name]: NavBar,
      [Icon.name]: Icon,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Tag.name]:Tag,
      [Dialog.name]: Dialog,
      [Overlay.name]: Overlay,
      [Loading.name]: Loading,
      [PullRefresh.name]: PullRefresh,
      [Divider.name]: Divider
    },
    data() {
      return {
        container: null,
        loadcontent: '加载中...',
        isLoading: false,
        active: 0,
        lscurorder: [],
        lsorder: [],
        fromid: 1,
        toid: 5,
        listCount: 5,
        ordertype: [
          { id: 0, key: "all", title: "全部订单", icon: "wap-home" },
          { id: 1, key: "nopay", title: "待付款", icon: "logistics" },
          { id: 2, key: "success", title: "已成功", icon: "underway-o" }
        ],
        finished: false
      }
    },
    watch: {
      active(val) {
        console.log('获得的active发生变化：' + val);
        setTimeout(() => {
          this.fromid = 1
          this.toid = this.listCount
          this.lscurorder = []
          this.finished
          this.GetCurOrderInfo(val);
        }, 300);
      },
    },
    mounted() {
      this.GetCurOrderInfo(0)
    },
    methods: {
      /* eslint-disable */
      ToWxPay(data) {
        var vm = this;
        //下面是解决WeixinJSBridge is not defined 报错的方法
        if (typeof WeixinJSBridge == "undefined") {
          //微信浏览器内置对象。参考微信官方文档
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              vm.onBridgeReady(data),
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(data));
            document.attachEvent("onWeixinJSBridgeReady", vm.onBridgeReady(data));
          }
        } else {
          vm.onBridgeReady(data);
        }
      },
      onBridgeReady(data) {
        console.log('111111start to wxpay  info  data:===>' + data)
        let jsonData = JSON.parse(data);
        // console.log('解析json成功')
        // console.log(jsonData);
        //window.alert('解析json成功，开始送过去支付')
        // Toast('解析json成功，开始送过去支付~');
        // console.log(WeixinJSBridge)
        // console.log('start alert ')
        // Toast(' ~'+WeixinJSBridge);
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": jsonData.app_id,        //公众号ID，由商户传入     
          "timeStamp": jsonData.time_stamp,        //时间戳，自1970年以来的秒数     
          "nonceStr": jsonData.nonce_str,       //随机串     
          "package": jsonData.package,
          "signType": jsonData.sign_type,       //微信签名方式：     
          "paySign": jsonData.pay_sign
        },
        function(res) {
            // window.alert(res.err_msg)
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              Dialog.alert({
                title: '成功提醒',
                message: '支付成功，等待微信服务器返回' ,
              }).then(() => {
                console.log('onclose')
              });
            }
        });
      },
      /* eslint-enable */
      onLoad() {
        this.isLoading = true
        if (this.finished === true) return
        // console.log('加载数据了，')
        this.fromid = this.toid + 1
        this.toid = this.fromid + this.listCount
        this.GetCurOrderInfo(this.active)
      },
      onRefresh() {
        this.lscurorder = []
        this.fromid = 1
        this.toid =5
        this.finished = false
        this.GetCurOrderInfo(this.active)
      },
      async RemoveOrder(payInfo)
      {
        Dialog.confirm({
          title: '确认删除订单',
          message: '您确认要删除 ' + payInfo.name + '['+ payInfo.outid +']   这笔充值订单吗，删除之后不能该笔订单将不能重新发起？',
        })
          .then(async () => {
            const res = await requestPayDeleteOrder({accesstoken: this.$store.state.accesstoken, id: payInfo.id, fromid: this.fromid ,toid: this.toid })
            if (res.return_code === '0') {
                Toast({
                        message: res.return_message,
                        icon: 'success',
                    });
                    this.lscurorder.forEach((info, index) => {
                      if (info.id === payInfo.id) {
                        this.lscurorder.splice(index, 1)
                      }
                    });
                   // this.GetCurOrderInfo (this.active);
                } else {
                    Toast({
                        message: res.return_message,
                        icon: 'fail',
                    });
                }
          });
      },
      toRePay (payInfo)
      {
        Dialog.confirm({
          title: '重新发起支付',
          message: '您确认要重新发起支付吗？',
        })
          .then(async () => {

            if(this.$store.state.typekey !== payInfo.account_type.toLowerCase())
            {
                Toast({
                    message: '目前不在支付的软件里面，请使用相应系统发起支付！' + this.$store.state.typekey + payInfo.account_type ,
                    icon: 'fail',
                });
                return;
            }
            console.log(payInfo)
            if (payInfo.account_type.toLowerCase()==='wechat') // 如果是微信
            {
              this.ToWxPay(payInfo.data1)
              return
            }

            if (payInfo.payurl === undefined || payInfo.payurl === '') {
                Toast({
                    message: '没有生成链接，不能发起支付！',
                    icon: 'fail',
                });
            }
            window.open(payInfo.payurl)
          })
      },
      async GetCurOrderInfo (ordertype) {
        // this.lscurorder = []
        this.isLoading = true
        const res = await requestGetPayRecentListInfo({ accesstoken: this.$store.state.accesstoken, ordertype: ordertype, fromid: this.fromid, toid: this.toid})
        // console.log(res)
        if (res.return_code === '0') {
          // this.lscurorder = res.rows
         
          if (res.rows.length < this.listCount) this.finished = true
          if (res.rows.length>0) {
            // this.lscurorder.push(res.rows)
            res.rows.forEach(info => {
              this.lscurorder.push(info)
            });
             
          }
          setTimeout(() => {
            this.isLoading = false
          }, 1000);
          
        } else {
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
            this.isLoading = false
            this.finished = true
        }
         
      },
      formatPrice(price) {
        return (price / 1).toFixed(2);
      }
    }
  };
  </script>
  
  <style lang="less">
  .card-goods {
    padding: 10px 0;
    background-color: #fff;
  
    &__item {
      position: relative;
      background-color: #fafafa;
  
      .van-checkbox__label {
        width: 100%;
        height: auto; // temp
        padding: 0 10px 0 15px;
        box-sizing: border-box;
      }
  
      .van-checkbox__icon {
        top: 50%;
        left: 10px;
        z-index: 1;
        position: absolute;
        margin-top: -10px;
      }
  
      .van-card__price {
        color: #f44;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  </style>
  